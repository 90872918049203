import { hostname, projectHosts } from '../../config'
let baseUrl;
//`development` 开发版本 `production`  生产版本
if (process.env.NODE_ENV === `development`) {
  baseUrl = `http://${projectHosts.admin}.${hostname}/portal`;//服务器地址前缀
  // baseUrl = `http://192.168.0.113:8100`

} else {
  baseUrl = `http://${projectHosts.admin}.${hostname}/portal`;//服务器地址前缀

}


export default baseUrl;


export const qxtUrl = process.env.NODE_ENV === `development`? `http://${projectHosts.qxt}.${hostname}/portal`:`http://${projectHosts.qxt}.${hostname}/portal`
export const patentUrl = process.env.NODE_ENV === `development`? `http://${projectHosts.service}.${hostname}/portal`:`http://${projectHosts.service}.${hostname}/portal`
export const examUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.service}.${hostname}/portal`:`http://${projectHosts.service}.${hostname}/portal`
export const policyUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.shenbao}.${hostname}/portal`:`http://${projectHosts.shenbao}.${hostname}/portal`
export const imgUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.admin}.${hostname}/portal`:`http://${projectHosts.admin}.${hostname}/portal`
export const certificationUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.qxt}.${hostname}/portal`:`http://${projectHosts.qxt}.${hostname}/portal`
export const indexUrl = process.env.NODE_ENV === `development` ? `http://${projectHosts.cms}.${hostname}`: `http://${projectHosts.cms}.${hostname}`
export const storebaseUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.data}.${hostname}`:`http://${projectHosts.data}.${hostname}`
export const resourceUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.data}.${hostname}`:`http://${projectHosts.data}.${hostname}`
export const nianfeiUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.service}.${hostname}/portal`:`http://${projectHosts.service}.${hostname}/portal`


// 第三方万方地址
export const wfUrl = "https://webstads.sciinfo.cn"
